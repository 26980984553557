* {
  box-sizing: border-box; 
}

:root {
  --gradient: linear-gradient(0deg,
    hsl(0, 0%, 0%) 0%,
    hsla(0, 0%, 0%, 0.738) 20%,
    hsla(0, 0%, 0%, 0.541) 34%,
    hsla(0, 0%, 0%, 0.382) 47%,
    hsla(0, 0%, 0%, 0.278) 56.5%,
    hsla(0, 0%, 0%, 0.194) 65%,
    hsla(0, 0%, 0%, 0.126) 73%,
    hsla(0, 0%, 0%, 0.075) 80.2%,
    /* hsla(0, 0%, 0%, 0.042) 86.1%, */
    /* hsla(0, 0%, 0%, 0.021) 91%, */
    /* hsla(0, 0%, 0%, 0.008) 95.2%,
    hsla(0, 0%, 0%, 0.002) 98.2%, */
    hsla(0, 0%, 0%, 0) 100%
  );
}

body {
  background: #000000;
}

.App {
  text-align: center;
  width: 100%;
  background-color: rgb(14, 11, 17);
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  font-family: -apple-system, BlinkMacSystemFont, Roboto, Arial, sans-serif;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
}

@media(max-width: 768px) {
  .App {
    overflow: auto;
  }
}

.App h1 {
  font-size: 48px;
  margin-bottom: 48px;
  margin-top: 0;
}

.App__start-container {
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;

  /* Animation */
  opacity: 0;
  animation: fadeIn 500ms ease-in-out forwards;
  animation-delay: 1000ms;
}

.App__start {
  /* background: rgba(255,255,255,.07); */
  background: rgb(105, 60, 255);
  border: 0;
  outline: 0;
  cursor: pointer;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.5px;
  color: #ffffff;
  display: flex;
  align-items: center;
  transition: all 150ms ease-in-out;
  padding: 14px 20px 12px 16px;
  border-radius: 8px;
  animation: glowing 700ms alternate infinite ease-in-out;
}

@keyframes glowing {
  from {
    box-shadow: 0 0 10px 5px rgba(99, 87, 255,.5);
  }
  to {
    box-shadow: 0 0 20px 10px rgba(99, 87, 255,.5);
  }
}

.App__start:hover,
.App__start:active,
.App__start:focus {
  color: rgba(255,255,255,1);
  box-shadow: 0 0 20px 10px rgba(99, 87, 255,.5);
  outline: 0;
  opacity: 1;
  animation: none;
}

.App__start svg {
  margin-right: 10px;
}

.App__start--done {
  transform: translateY(-100%);
  opacity: 0;
}

.App__start-helper-text {
  font-size: 14px;
  font-weight: 500;
  color: #666666;
  margin-top: 14px;
}

.App__made-by {
  position: absolute;
  bottom: 24px;
  left: 0;
  width: 100%;
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  color: #999999;
  text-decoration: none;
}

.App__made-by a,
.App__made-by a:hover,
.App__made-by a:active,
.App__made-by a:visited,
.App__made-by a:focus {
  font-weight: 600;
  color: #ffffff;
  text-decoration: none;
}

.App__share,
.App__home {
  display: flex;
  align-items: flex-end;
  padding: 10px 12px;
  justify-content: flex-end;
  background: none;
  color: white;
  opacity: 0.8;
  border: 0;
  outline: 0;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  background: rgba(255,255,255,.07);
  border-radius: 8px;
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  transition: all 150ms ease-in-out;
  z-index: 1;
  text-decoration: none;
}

.App__home {
  left: 15px;
  right: unset;
  line-height: 16px;
  align-items: center;
}

.App__ph {
  left: 15px;
  position: absolute;
  bottom: 15px;
  line-height: 16px;
  align-items: center;
}

.App__share:hover,
.App__share:hover,
.App__share:active,
.App__share:focus,
.App__home:hover,
.App__home:hover,
.App__home:active,
.App__home:focus {
  background: rgba(255,255,255,.10);
  opacity: 1;
  text-decoration: none;
}

.App__share svg,
.App__home svg,
.ShareOption svg {
  width: 18px;
  margin-right: 5px;
}

.ShareOption {
  display: flex;
  line-height: 13px;
  align-items: flex-end;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(5px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.App__overlay {
  opacity: 0;
  background: #000000;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  pointer-events: none;
  transition: all 250ms ease;
  z-index: 1;
}

.App__share-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(0,0,0,.5);
  z-index: 1;
  animation: fadeIn 350ms ease-in-out forwards;
  opacity: 0;
}

.App__share-choices {
  width: 400px;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  background: rgba(50,50,50,.97);
  padding: 24px;
  border-radius: 8px;
}

.App__share-choice {
  font-size: 18px;
  font-family: 'Helvetica';
  line-height: 1.5em;
  font-weight: 600;
  color: #ffffff;
  padding: 20px 32px;
  flex-grow: 1;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: rgba(255,255,255,0);
  border: 0;
  border-radius: 8px;
  outline: 0;
  transition: all 150ms ease-in-out;
  cursor: pointer;
  text-decoration: none;
}

.App__share-choice:hover,
.App__share-choice:active,
.App__share-choice:focus {
  color: rgba(255,255,255,1);
  background: rgba(255,255,255,.10);
  outline: 0;
  text-decoration: none;
}

.App__mobile {
  font-size: 16px;
  padding: 32px;
  line-height: 1.5em;
  font-weight: 500;
}

.App__overlay--visible {
  opacity: 1;
}

.App__content,
.App__content .App__video-container {
  visibility: hidden;
  display: none;
  background: #000000;
}

.App__content--visible,
.App__content--visible .App__video-container {
  visibility: visible;
  display: unset;
}

.App__video {
  width: 100%;
  height: calc(100vh - 100px);
  object-fit: cover;
  object-position: top;
}

.App__video-container {
  position: relative;
  width: 100%;
}

.App__video-overlay {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 120px;
  background: var(--gradient);
  pointer-events: none;
}

.App__choices {
  display: flex;
  flex-direction: row;
  padding: 16px;
  width: 100%;
  justify-content: space-around;
  align-items: flex-start;
  height: 100px;
  position: relative;
  top: -10px;
}

.App__choice {
  font-size: 14px;
  font-family: 'Helvetica';
  line-height: 1.5em;
  font-weight: 600;
  letter-spacing: 1.5px;
  color: #ffffff;
  padding: 20px 32px;
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-transform: uppercase;
  background: rgba(255,255,255,0);
  border: 0;
  border-radius: 8px;
  outline: 0;
  transition: all 150ms ease-in-out;
  cursor: pointer;
  animation: fadeIn 350ms ease-in-out forwards;
  opacity: 0;
  text-decoration: none;
}

.App__choice:hover,
.App__choice:active,
.App__choice:focus {
  color: rgba(255,255,255,1);
  background: rgba(255,255,255,.10);
  outline: 0;
  text-decoration: none;
}

.App__choice--visited {
  color: rgba(255,255,255,0.4);
}

@media(max-width: 768px) {
  .App__choices {
    flex-direction: column;
    height: auto;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .App__choice {
    width: 100%;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
